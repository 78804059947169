import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { DiagnosticoComponent, PlanesPersonalesComponent, PlanPersonalSesionComponent } from "@puntaje/puntaje/core"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

export const routes: Routes = [
    {
        path: "planes_personales",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanesPersonalesComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "diagnostico",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: DiagnosticoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_personal/:id/ciclo/:ciclo/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanPersonalSesionComponent,
                canActivate: [AuthGuard],
                data: { goToDiagnosticoAtEnd: true }
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
